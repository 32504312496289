import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { CoreNavigationService } from 'app/core/navigation/CoreNavigationService';
import { ServiceCode } from 'app/generated/backend/types/service-code';
import { ShipmentPartyRole } from 'app/generated/backend/types/shipment-party-role';
import { ShipmentService } from 'app/generated/backend/shipment/api/shipment-service';
import { ShipmentStatus } from 'app/generated/backend/types/shipment-status';
import { PartyTaskType } from 'app/generated/backend/types/party-task-type';
import { ProcessVariant } from 'app/generated/backend/types/process-variant';
import { ShipmentType } from 'app/generated/backend/types/shipment-type';
import { filter, Subscription } from 'rxjs';
import { ShipmentDetailService } from '../detail/ShipmentDetailService';
import { Shipment } from 'app/generated/backend/shipment/api/shipment';

@Component({
	templateUrl: './ShipmentMenuComponent.html',
	selector: 'app-shipment-menu',
	animations: [
		trigger('slideInOut', [
			transition(':enter', [
				style({ transform: 'translateX(500%)' }),
				animate('500ms ease-in', style({ transform: 'translateX(0%)' }))
			]),
			transition(':leave', [
				animate('500ms ease-in', style({ transform: 'translateX(-500%)' }))
			])
		])
	]
})
export class ShipmentMenuComponent implements OnInit, OnDestroy {

	public href: string = "";
	public ssid: string = "";
	public cargoDetailLinkActive: boolean = false;
	public containerDetailLinkActive: boolean = false;
	public packageDetailLinkActive: boolean = false;
	public packageListDetailLinkActive: boolean = false;
	public documentDetailLinkActive: boolean = false;
	public serviceScheduleLinkActive: boolean = false;
	public serviceSuppliersLinkActive: boolean = false;
	public bookingDetailsLinkActive: boolean = false;
	public deliveryPlanningLinkActive: boolean = false;
	public releaseDetailsLinkActive: boolean = false;
	public shipmentNumber: string;
	public shipment: Shipment;
	public hasCargoFeature: boolean = false;
	public hasPackingList: boolean = false;
	public isLclParty: boolean = false;
	private subscriptions: Subscription[] = new Array<Subscription>();
	public ShipmentStatus = ShipmentStatus;
	public ServiceCode = ServiceCode;
	public isShipmentFcl: boolean = false;
	public partyId: number;
	public shipmentHasDeliveryPlanTask: boolean = false;
	public partyHasDeliveryPlanningRoles: boolean = false;
	public partyRoles: ShipmentPartyRole = ShipmentPartyRole.None;
	public deliveryServiceHandPoint: boolean = false;
	public ShipmentProcess = ProcessVariant;

	constructor(
		protected activatedRoute: ActivatedRoute,
		protected shipmentDetailService: ShipmentDetailService,
		protected authenticationService: CoreAuthenticationService,
		private navigationService: CoreNavigationService,
		private router: Router
	) {
		router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.href = this.router.url;
				this.ssid = activatedRoute.snapshot.queryParamMap.get('ssid');
				this.processLinkActive();
				this.processLinkActive2();
				this.processLinkActive3();
			}
		});
		this.partyId = this.authenticationService.getPartyId();
	}

	processLinkActive() {
		if (this.href.indexOf("/shipment/cargo/detail") > -1) {
			this.cargoDetailLinkActive = true;
		} else {
			this.cargoDetailLinkActive = false;
		}
		if (this.href.indexOf("/shipment/container/detail") > -1) {
			this.containerDetailLinkActive = true;
		} else {
			this.containerDetailLinkActive = false;
		}
		if (this.href.indexOf("/shipment/package/detail") > -1) {
			this.packageDetailLinkActive = true;
		} else {
			this.packageDetailLinkActive = false;
		}
		if (this.href.indexOf("/shipment/packageList/detail") > -1) {
			this.packageListDetailLinkActive = true;
		} else {
			this.packageListDetailLinkActive = false;
		}
	}

	processLinkActive2() {
		if (this.href.indexOf("/shipment/document/detail") > -1) {
			this.documentDetailLinkActive = true;
		} else {
			this.documentDetailLinkActive = false;
		}
		if (this.href.indexOf("/shipment/service/schedule") > -1) {
			this.serviceScheduleLinkActive = true;
		} else {
			this.serviceScheduleLinkActive = false;
		}
		if (this.href.indexOf("/shipment/service/suppliers") > -1) {
			this.serviceSuppliersLinkActive = true;
		} else {
			this.serviceSuppliersLinkActive = false;
		}
		if (this.href.indexOf("/shipment/service/bookings/detail") > -1) {
			this.bookingDetailsLinkActive = true;
		} else {
			this.bookingDetailsLinkActive = false;
		}
		if (this.href.indexOf("/shipment/deliveryPlanning") > -1) {
			this.deliveryPlanningLinkActive = true;
		} else {
			this.deliveryPlanningLinkActive = false;
		}
	}

	processLinkActive3() {
		if (this.href.indexOf("/shipment/service/releases/detail") > -1) {
			this.releaseDetailsLinkActive = true;
		} else {
			this.releaseDetailsLinkActive = false;
		}
	}

	ngOnInit() {
		this.subscriptions.push(this.shipmentDetailService.detailChanged.subscribe(detail => {
			this.hasCargoFeature = this.shipmentDetailService.hasCargoFeature();
			this.hasPackingList = this.shipmentDetailService.hasPackingList();
			this.isLclParty = this.shipmentDetailService.isSupplierLclParty();
			if (detail && detail.shipment) {
				this.shipment = detail.shipment;
				if (this.shipment.services) {
					const deliveryServicesHandPointCount = this.shipment.services?.filter(service => service.serviceCode == ServiceCode.Delivery && !service.supplierPartyId && service.status !== ShipmentStatus.Rejected).length;
					this.deliveryServiceHandPoint = deliveryServicesHandPointCount > 0;
				}
				if (this.shipment.type === ShipmentType.Lcl) {
					this.isShipmentFcl = false;
				}
				if (this.shipment.type === ShipmentType.Fcl) {
					this.isShipmentFcl = true;
				}
				if (detail.tasks?.filter(t => t.type === PartyTaskType.ReceiverAcceptDeliveryPlan).length > 0) {
					this.shipmentHasDeliveryPlanTask = true;
				}
				this.partyRoles = ShipmentPartyRole.None;
				this.shipment.parties?.forEach(shipmentParty => {
					if (this.partyId == shipmentParty.partyId) {
						this.partyRoles = this.partyRoles | shipmentParty.role;
					}
				});
				this.partyHasDeliveryPlanningRoles = this.getMask(this.partyRoles, ShipmentPartyRole.Receiver);
			} else {
				this.shipment = null;
			}
		}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => {
			sub.unsubscribe();
		});
	}

	getMask(value: number, mask: number): boolean {
		return (value & mask) !== 0;
	}

	isMultipleServices(service: ShipmentService) {
		if (this.shipment.services?.filter(s => s.serviceCode === service.serviceCode)?.length > 0) {
			return true;
		}
		return false;
	}

	isSecondService(service: ShipmentService) {
		if (!this.shipment.services) {
			return false;
		}
		const count = this.shipment.services.filter(s => s.serviceCode === service.serviceCode)?.length;
		if (count === 1) {
			return false;
		}
		const idx = this.shipment.services.findIndex(s => s.id === service.id);
		if (idx === 0) {
			return false;
		}
		if (this.shipment.services[idx - 1].serviceCode === service.serviceCode) {
			return true;
		}
		return false;
	}

	isMenuServiceSameCodeWithUrlService(service: ShipmentService) {
		if (this.ssid !== '') {
			const urlService = this.shipment.services.find(s => `${s.id}` === this.ssid);
			if (urlService?.serviceCode === service.serviceCode) {
				return true;
			}
		}
		return false;
	}

	isMenuActive(service: ShipmentService) {
		let active = false;
		if (this.isMultipleServices(service)) {
			if (service.serviceCode === ServiceCode.Pickup) {
				if (this.href.indexOf("shipment/service/pickup") > -1) {
					active = true;
				}
			} else if (service.serviceCode === ServiceCode.Delivery) {
			}
		} else {
		}
		if (!active) {
			active = this.ssid === `${service.id}`;
		}
		if (!active) {
			active = this.isMenuServiceSameCodeWithUrlService(service);
		}
		return active;
	}

	isMenuStrike(service: ShipmentService) {
		const serviceRejectedCount = this.shipment.services.filter(s => s.serviceCode === service.serviceCode && s.status === ShipmentStatus.Rejected).length;
		const serviceCount = this.shipment.services.filter(s => s.serviceCode === service.serviceCode).length;

		if (serviceRejectedCount === serviceCount) {
			return true;
		}
		return false;
	}

	isServiceMissingSupplier(service: ShipmentService) {
		if (this.shipment.process === ProcessVariant.Tracking) {
			return false;
		}
		const serviceMissingSupplierCount = this.shipment.services.filter(s => s.serviceCode === service.serviceCode && s.status !== ShipmentStatus.Rejected && !s.supplierPartyId).length;
		if (serviceMissingSupplierCount) {
			return true;
		}
		return false;
	}


	onClickItem(service: ShipmentService) {
		if (this.isMultipleServices(service)) {
			if (service.serviceCode === ServiceCode.Pickup) {
				this.navigationService.navigateShipmentPickupMaster(this.shipment?.number);
			} else if (service.serviceCode === ServiceCode.Delivery) {
				this.navigationService.navigateShipmentConfirmDeliveryPlanning(this.shipment?.number, null, service.id);
			}
		} else {
			this.navigationService.navigateShipmentOverview(this.shipment?.number, service.id);
		}
	}

}
