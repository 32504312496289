<div *ngIf="shipment">
  <div class="adn-menu-header">{{shipment.number}}</div>
  <button mat-menu-item #dashBoardLink="routerLinkActive" [routerLink]="['/shipment/overview']"
    [queryParams]="{sn: shipment.number}" routerLinkActive="adn-color-primary" [routerLinkActiveOptions]="{exact: true}"
    [ngClass]="{'adn-color-primary': !dashBoardLink.isActive, 'adn-shipment-menu-item-active': dashBoardLink.isActive}">
    <span class="fal fa-fw fa-material fa-home" aria-hidden="true"></span>&nbsp;{{'nav.overview' |
    translate}}
  </button>
  <button mat-menu-item #partyLink="routerLinkActive" [routerLink]="['/shipment/parties']"
    [queryParams]="{sn: shipment.number}" routerLinkActive="adn-color-primary"
    [routerLinkActiveOptions]="{exact: false}"
    [ngClass]="!partyLink.isActive ? 'adn-color-primary' : 'adn-shipment-menu-item-active'">
    <span class="fal fa-fw fa-material fa-building" aria-hidden="true"></span>&nbsp;{{'nav.parties' | translate}}
  </button>
  <button *ngIf="shipment.pickupLocation" mat-menu-item #locationPickupLink="routerLinkActive"
    [routerLink]="['/shipment/location']" [queryParams]="{sn: shipment.number, lt: 'pickup'}"
    routerLinkActive="adn-color-primary" [routerLinkActiveOptions]="{exact: false}"
    [ngClass]="!locationPickupLink.isActive ? 'adn-color-primary' : 'adn-shipment-menu-item-active'">
    <span class="fal fa-fw fa-material fa-warehouse" aria-hidden="true"></span>&nbsp;{{'nav.pickupPlace' |
    translate}}
    &nbsp;<span *ngIf="shipment.pickupLocation.submittedAt" class="fal fa-fw fa-material fa-check"
      aria-hidden="true"></span>
  </button>
  <button *ngIf="shipment.deliveryLocation" mat-menu-item #locationDeliveryLink="routerLinkActive"
    [routerLink]="['/shipment/location']" [queryParams]="{sn: shipment.number, lt: 'delivery'}"
    routerLinkActive="adn-color-primary" [routerLinkActiveOptions]="{exact: false}"
    [ngClass]="!locationDeliveryLink.isActive ? 'adn-color-primary' : 'adn-shipment-menu-item-active'">
    <span class="fal fa-fw fa-material fa-warehouse" aria-hidden="true"></span>&nbsp;{{'nav.deliveryPlace' |
    translate}}
    &nbsp;<span *ngIf="shipment.deliveryLocation.submittedAt" class="fal fa-fw fa-material fa-check"
      aria-hidden="true"></span>
  </button>
  <button *ngIf="hasCargoFeature" mat-menu-item #cargoLink="routerLinkActive" [routerLink]="['/shipment/cargo/master']"
    [queryParams]="{sn: shipment.number}" routerLinkActive="" [routerLinkActiveOptions]="{exact: false}"
    [ngClass]="cargoLink.isActive || cargoDetailLinkActive ? 'adn-shipment-menu-item-active': 'adn-color-primary' ">
    <span class="fal fa-fw fa-material fa-cubes" aria-hidden="true"></span>&nbsp;{{'nav.cargo' | translate}}
  </button>
  <button *ngIf="!isLclParty" mat-menu-item #containerLink="routerLinkActive"
    [routerLink]="['/shipment/container/master']" [queryParams]="{sn: shipment.number}" routerLinkActive=""
    [routerLinkActiveOptions]="{exact: false}"
    [ngClass]="containerLink.isActive || containerDetailLinkActive ? 'adn-shipment-menu-item-active' : 'adn-color-primary'">
    <span class="fal fa-fw fa-material fa-container-storage" aria-hidden="true"></span>&nbsp;{{'nav.containers' |
    translate}}
  </button>
  <button mat-menu-item *ngIf="shipment.process !== ShipmentProcess.Tracking" #packageLink="routerLinkActive"
    [routerLink]="['/shipment/package/master']" [queryParams]="{sn: shipment.number}" routerLinkActive=""
    [routerLinkActiveOptions]="{exact: false}"
    [ngClass]="packageLink.isActive || packageDetailLinkActive ? 'adn-shipment-menu-item-active' : 'adn-color-primary'">
    <span class="fal fa-fw fa-material fa-box" aria-hidden="true"></span>&nbsp;{{'nav.packages' |
    translate}}
  </button>
  <button *ngIf="hasPackingList" mat-menu-item #packingListLink="routerLinkActive"
    [routerLink]="['/shipment/packingList/master']" [queryParams]="{sn: shipment.number}" routerLinkActive=""
    [routerLinkActiveOptions]="{exact: false}"
    [ngClass]="packingListLink.isActive || packageListDetailLinkActive ? 'adn-shipment-menu-item-active' : 'adn-color-primary'">
    <span class="fal fa-fw fa-material fa-box" aria-hidden="true"></span>&nbsp;{{'nav.packingLists' |
    translate}}
  </button>
  <button mat-menu-item #documentLink="routerLinkActive" [routerLink]="['/shipment/document/master']"
    [queryParams]="{sn: shipment.number}" routerLinkActive="" [routerLinkActiveOptions]="{exact: false}"
    [ngClass]="documentLink.isActive || documentDetailLinkActive ? 'adn-shipment-menu-item-active': 'adn-color-primary'">
    <span class="fal fa-fw fa-material fa-file-invoice" aria-hidden="true"></span>&nbsp;{{'nav.documents' |
    translate}}
  </button>
  <button *ngIf="shipment.charges" mat-menu-item #chargeLink="routerLinkActive" [routerLink]="['/shipment/charges']"
    [queryParams]="{sn: shipment.number}" routerLinkActive="adn-color-primary"
    [routerLinkActiveOptions]="{exact: false}"
    [ngClass]="!chargeLink.isActive ? 'adn-color-primary' : 'adn-shipment-menu-item-active'">
    <span class="fal fa-fw fa-material fa-file-invoice-dollar" aria-hidden="true"></span>&nbsp;{{'field.charges.label' |
    translate}}
  </button>
  <ng-container *ngIf="shipment.firstSubmittedAt">
    <div *ngFor="let service of shipment.services; let i = index">
      <ng-container>
        <button *ngIf="!isSecondService(service) && shipment.process === ShipmentProcess.Collaboration" mat-menu-item
          #serviceLink="routerLinkActive" (click)="onClickItem(service)" [routerLink]="['/shipment/service/overview']"
          [queryParams]="{sn: shipment.number, ssid: service.id}" routerLinkActive=""
          [routerLinkActiveOptions]="{exact: false}" [ngClass]="{'adn-color-primary': !isMenuActive(service) && !serviceLink.isActive && !serviceScheduleLinkActive && !serviceSuppliersLinkActive, 
          'adn-shipment-menu-item-active' : isMenuActive(service) || serviceLink.isActive || (serviceScheduleLinkActive && service.id?.toString() === ssid) || (serviceSuppliersLinkActive && service.id?.toString() === ssid), 
          'state-deleted' : isMenuStrike(service)}">
          <span class="fal fa-fw fa-material" [ngClass]="service.serviceCode | serviceCodeClass : service.mode"
            aria-hidden="true"></span>&nbsp;{{service.serviceCode
          | serviceCode : 'labelPlural' | async}}&nbsp;
          <span [@slideInOut] *ngIf="isServiceMissingSupplier(service)" class="fal fa-fw fa-material fa-hand-point-left"
            aria-hidden="true"></span>
        </button>
      </ng-container>
      <div
        *ngIf="isShipmentFcl && service.serviceCode == ServiceCode.Delivery && i > 0
                && shipment.services[i-1].serviceCode !== ServiceCode.Delivery && partyHasDeliveryPlanningRoles && shipmentHasDeliveryPlanTask"
        class="adn-menu-indent">
        <button mat-menu-item #deliveryPlanLinkActive="routerLinkActive" [routerLink]="['/shipment/deliveryPlanning']"
          [queryParams]="{sn: shipment.number, ssid: service.id, lt: 'delivery'}" routerLinkActive=""
          [routerLinkActiveOptions]="{exact: false}"
          [ngClass]="deliveryPlanningLinkActive ? 'adn-shipment-menu-item-active' : 'adn-color-primary' ">
          <span class="fal fa-calendar fa-fw fa-material"
            aria-hidden="true"></span>&nbsp;{{'nav.shipmentServiceDeliveryPlan' |
          translate}}
        </button>
      </div>
      <div *ngIf="service.serviceCode === ServiceCode.OriginHandling"
        [ngClass]="shipment.process === ShipmentProcess.Collaboration?'adn-menu-indent':null">
        <button mat-menu-item #serviceBookingsLink="routerLinkActive"
          [routerLink]="['/shipment/service/bookings/master']" [queryParams]="{sn: shipment.number, ssid: service.id}"
          routerLinkActive="" [routerLinkActiveOptions]="{exact: false}"
          [ngClass]="serviceBookingsLink.isActive || bookingDetailsLinkActive ? 'adn-shipment-menu-item-active' : 'adn-color-primary'">
          <span class="fal fa-book fa-fw fa-material" aria-hidden="true"></span>&nbsp;{{'nav.shipmentServiceBookings' |
          translate}}
        </button>
      </div>
      <div
        *ngIf="service.serviceCode === ServiceCode.DestinationHandling && shipment.process === ShipmentProcess.Collaboration"
        class="adn-menu-indent">
        <button mat-menu-item #serviceReleasesLink="routerLinkActive"
          [routerLink]="['/shipment/service/releases/master']" [queryParams]="{sn: shipment.number, ssid: service.id}"
          routerLinkActive="" [routerLinkActiveOptions]="{exact: false}"
          [ngClass]="serviceReleasesLink.isActive || releaseDetailsLinkActive ? 'adn-shipment-menu-item-active' : 'adn-color-primary' ">
          <span class="fal fa-truck-loading fa-fw fa-material"
            aria-hidden="true"></span>&nbsp;{{'nav.shipmentServiceReleases' |
          translate}}
        </button>
      </div>
    </div>
  </ng-container>
</div>